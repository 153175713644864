import React from "react";

function Option() {
    return(
        <div>
            <img src="https://ttol.vietnamnetjsc.vn/images/2024/04/23/10/36/Hanh-Sino-7.png" alt="pic" />
        </div>
    )
}

export default Option;